<template>
  <v-card min-height="100vh" outlined flat class="rounded-10 overflow-hidden">
    <v-card-title style="min-height: 100px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="$router.push({ path: item.href })"
              :disabled="item.disabled"
            >
              <span class="white--text cursor-pointer">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-btn
          @click="$router.go(-1)"
          small
          depressed
          class="text-none primary--text"
          color="white"
        >
          <v-icon color="primary" left>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text class="py-4">
      <v-tabs fixed-tabs>
        <v-tab
          @click="activeSub = sub"
          class="text-none primary--text"
          v-for="sub in category.subcategories"
          :key="sub.id"
          active-class="fw-900"
        >
          {{ sub.title | ucwords }}
        </v-tab>
      </v-tabs>
      <v-row v-if="items.length" dense align="stretch" justify="start">
        <v-col
          cols="12"
          md="4"
          sm="12"
          v-for="series in items"
          :key="series.id"
        >
          <EachCourse
            @click="
              $router.push({
                name: 'courses-view',
                params: { slug: series.slug }
              })
            "
            :item="series"
          ></EachCourse>
        </v-col>
      </v-row>
      <PageLoader min-height="100vh" v-else-if="loading_items"></PageLoader>
      <Empty
        v-else-if="!items.length"
        min-height="100vh"
        :headline="`No course found for ${activeSub.title}`"
      ></Empty>
    </v-card-text>
    <v-card-actions
      v-if="has_more_items"
      class="d-flex align-center justify-center"
    >
      <v-btn
        @click="loadMoreItems"
        class="text-none"
        min-width="200"
        :loading="loading_more_items"
        depressed
        :disabled="loading_more_items"
        color="primary"
      >
        Load more
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { fetch_services } from '@/services/fetch/fetch_services'
import EachCourse from './components/EachCourse.vue'
export default {
  components: { EachCourse },
  data: () => ({
    category: null,
    activeSub: null
  }),
  mixins: [fetch_services],
  watch: {
    '$route.params.slug': {
      handler: function (val) {
        this.fetchCategory(val)
      },
      immediate: true
    },
    activeSub: {
      handler: function (val) {
        if (val) {
          this.fetchSubCategorySeries(val.slug)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    paths() {
      let x = [
        {
          text: 'Courses',
          disabled: false,
          href: '/dashboard/courses'
        }
      ]
      if (this.category) {
        x.push({
          text: this.category.title,
          disabled: true,
          href: null
        })
      }
      return x
    }
  },
  methods: {
    fetchCategory(slug) {
      request.get(`api/courses/category/${slug}`).then(({ data }) => {
        this.category = data
        this.activeSub = data.subcategories.length
          ? data.subcategories[0]
          : null
      })
    },
    fetchSubCategorySeries(slug) {
      this.loadItems(`api/courses/category/${slug}/series`, true, null, null)
    }
  }
}
</script>

<style></style>
