<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 3 : 0"
      flat
      @click="$emit('click', item)"
      outlined
      class="rounded-10 ma-2 cursor-pointer fill-height"
    >
      <v-img
        max-height="200"
        :src="item.preview ? item.preview.url_inline : settings.noImagePreview"
      ></v-img>
      <v-card-text class="d-flex flex-column">
        <strong class="primary--text fs-18 fw-900">
          {{ item.title | ucwords }}
        </strong>
        <div
          class="secondary--text fs-13 fw-400"
          :inner-html.prop="item.contents | truncate(160)"
        ></div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'EachCourse',
  props: {
    item: { type: Object, required: true }
  }
}
</script>

<style></style>
